import { shallowEqual, useSelector,useDispatch } from "react-redux";
import { Pagination, Table  } from "antd";
import { useCallback, useEffect } from "react";
import * as actions from "redux/country/Actions";

import {
  DeleteButton,
  EditButton} from "components/button/Buttons";

function CountryTabView({ user,drawer,setDrawer,setEditRecord,setPageSize,setPageNumber,setDeleteRecord,pageSize,country,pageNumber }) {
  const dispatch = useDispatch();

  const {
    relationCountryRecords,
    relationCount,
    loading,
  } = useSelector(
    (state) => ({
      relationCountryRecords: state.country.relationCountryRecords || 0,
      relationCount: state.country.relationCount  || 0,
      loading: state.country.loading.getAllRelated,
    }),
    shallowEqual
  );

  let query = `relations[]=content&select[name_en]=true&select[id]=true&select[createdAt]=true&limit=${pageSize}&offset=0`;
  if (country) {
    query += `&where[id]=${country}`;
  }
  
  const fetchContent = useCallback(
    () => {
      // dispatch(actions.getAllRelated(paginationContent));
      dispatch(actions.getAllRelated({ query: query }));

    },
    // eslint-disable-next-line
    [pageSize, pageNumber, dispatch,drawer,country]
  );

  useEffect(
    () => {
      fetchContent();
    },
    // eslint-disable-next-line
    [dispatch, pageSize, pageNumber,drawer,country]
  );

  const columns = [
    {
      title: (
        <>
          Country<span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (text_en, entity) => {
        return <>{entity?.name_en}</>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text_en, entity) => {
        // Construct the URL based on the given conditions
        let url = "";
          url = `${process.env.REACT_APP_BASE_URL_USER}en/clinics/${entity.name_en?.toLowerCase()?.replace(/ /g, "-")?.replace(/-&-/g, "-")}`;

        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              
              {(user.role === "admin" ||
                user.role === "author" ||
                user.role === "editor") && (
                <span className="nav-link mx-1">
                  <EditButton
                    onClick={() => {
                      setEditRecord(entity);
                      setDrawer("Country");
                    }}
                  />
                </span>
              )}
              {user.role === "admin" && (
                <span className="nav-link ">
                  <DeleteButton onClick={() => setDeleteRecord(entity)} />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };

  return (
   <>
    <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: "calc(100vh - 420px)" }}
            pagination={false}
            dataSource={relationCountryRecords}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={relationCount}
            />
          </div>
        </div>
   </>
  );
}

export default CountryTabView;
