import { Upload, notification } from "antd";
import {
  addDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  setHours,
} from "date-fns";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { firebaseLogout } from "../../firebase/firebase";
import { firebaseConfig } from "../../firebase/firebase-config";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const formatDate = (datetime, dateFormat = "dd-MM-yyyy") => {
  return format(new Date(datetime), dateFormat);
};

export const formatDatenew = (datetime, dateFormat = "YYYY/MM/DD") => {
  return format(new Date(datetime), dateFormat);
};

export const formatDateInMonthString = (
  datetime,
  dateFormat = "MMMM  dd,yyyy"
) => {
  return format(new Date(datetime), dateFormat);
};

export const timePassedInUnixtimestamp = (unxiTimeStamp) => {
  var a = new Date(unxiTimeStamp * 1000);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var date = a.getDate();
  if (date < 10) {
    date = "0" + date;
  }
  var time = year + "/" + month + "/" + date;
  return time;
};

export const getRemainingDays = (unxiTimeStamp) => {
  var convertedDate = new Date(unxiTimeStamp * 1000);
  let difference_In_Days = differenceInDays(
    new Date(convertedDate),
    new Date(),
    { addSuffix: true }
  );
  let difference_In_Hours = differenceInHours(
    new Date(convertedDate),
    new Date(),
    { addSuffix: true }
  );
  let Difference_In_Minute = differenceInMinutes(
    new Date(convertedDate),
    new Date(),
    { addSuffix: true }
  );
  if (difference_In_Days > 0) {
    return difference_In_Days + " days";
  } else if (difference_In_Hours > 0) {
    return difference_In_Hours + " hours";
  } else {
    return Difference_In_Minute + " minutes";
  }
};

export const getTwoOClockTimeDifference = () => {
  const now = new Date();
  const date = new Date();
  const hours = date.getHours();
  const amPM = hours < 12 ? "AM" : "PM";
  let twoAMNew = setHours(new Date(), 2);
  let twoAM = setHours(new Date(), 2);
  const diffInMinutes = differenceInMinutes(now, twoAM) % 60;
  let diffInHours = 0;
  if (amPM === "AM") {
    if (hours > 2) {
      twoAMNew = setHours(addDays(new Date(), 1), 2);
    }
    diffInHours = differenceInHours(twoAMNew, now);
  } else if (amPM === "PM") {
    diffInHours = differenceInHours(now, twoAMNew);
    if (hours > 12) {
      diffInHours = 24 - diffInHours;
    }
  }
  if (diffInHours > 0) {
    return diffInHours + " hours";
  } else {
    return diffInMinutes + " minutes";
  }
};

export const getIdToken = () => {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken()
          .then(function (idToken) {
            resolve(idToken);
          })
          .catch(function (error) {
            resolve("");
          });
      } else {
        resolve("");
      }
    });
  });
};

export const createQueryParams = (
  limit = 30,
  offset = 0,
  text = null,
  relations = [],
  where = "",
  includeOverview="",
  onlyActive="",
  orderBy="",
  userIdquery=""
) => {
  let query = `limit=${limit}&offset=${offset}`;
  if (text) {
    query = query + `&text=${text}`;
  }
  if (where !== "") {
    query = query + `&${where}`;
  }
  if (includeOverview !== "") {
    query = query + `&${includeOverview}`;
  }
  if (onlyActive !== "") {
    query = query + `&${onlyActive}`;
  }
  
  if (relations.length>0) {
    const relationsQuery = relations
      .map((relation) => `&relations[]=${relation}`)
      .join("");

    query += relationsQuery;
  }
  if (orderBy !== "") {
    query = query + `&${orderBy}`;
  }
  if (userIdquery !== "") {
    query = query + `&${userIdquery}`;
  }
  
  return query;
};

export const createQueryParamsSlug = (
  slugType = "",
  limit = 30,
  offset = 0,
  text = null,
  relations = [],
  where = "") => {
  let query = `${slugType}&limit=${limit}&offset=${offset}`;
  if (text) {
    query = query + `&text=${text}`;
  }
  if (where !== "") {
    query = query + `&${where}`;
  }
  if (relations) {
    const relationsQuery = relations
      .map((relation) => `&relations[]=${relation}`)
      .join("");

    query += relationsQuery;
  }

  return query;
};
export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const logOutHandler = async (dispatch, authAction, navigate) => {
  try {
    localStorage.removeItem("token");
    dispatch(authAction.logout());
    await firebaseLogout();
    navigate("/login");
  } catch (error) {}
};

export const JsonToCSV = (data) => {
  const header = Object.keys(data[0]).join(",");
  const rows = data.map((obj) => Object.values(obj).join(","));
  return `${header}\n${rows.join("\n")}`;
};

export const downloadFile = (name, content) => {
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  if (link.download !== undefined) {
    // For modern browsers
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const convertCsvToJson = (content) => {
  const csvData = content;
  const rows = csvData.split("\n");
  const headers = rows[0].split(",");
  const jsonDataArray = [];

  for (let i = 1; i < rows.length; i++) {
    const values = rows[i].split(",");
    const rowObject = {};

    for (let j = 0; j < headers.length; j++) {
      rowObject[headers[j]] = values[j];
    }

    jsonDataArray.push(rowObject);
  }

  return jsonDataArray;
};

export const validateFileExtension = (file, allowedExtensions) => {
  const fileNameParts = file.name.split(".");
  const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();

  const isValid = allowedExtensions.some((e) => e.toLowerCase() === extension);

  if (!isValid) {
    notification.error({
      message: `Only ${allowedExtensions.join(", ")} extensions are allowed.`,
    });
  }

  return isValid || Upload.LIST_IGNORE;
};

export const sanitizeString = (str = "") => str?.trim();

const unicodePairs = [
  {
    key: "U+0003A",
    value: ":",
  },
  {
    key: "+++",
    value: ":",
  },
];

export const convertUnicodes = (str = "") => {
  unicodePairs.forEach((pair) => {
    if (str.includes(pair.key)) {
      str.replace(pair.key, pair.value);
    }
  });
  return str.trim();
};
