import { useState } from "react";
import { createQueryParams } from "utils/helpers/helper-functions";
import RequestsDrawer from "./request.drawer";
import RequestsTable from "./request.table";
import { requestRelations as relations } from "services/relations";
import { countryRelations  } from "services/relations";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "antd";
import RequestInfoModal from "./request-info.modal";
import RequestStatusModal from "./request-status.modal";
import RequestDetailModal from "./request-detail.modal"
import EventModal from "./event.modal"
import EventCompletedModal from "./event.completed.modal"
import RequestUpdateModal from "./request-update.modal";
import RequestCommentModal from "./request-comment.modal";
import RequestPaymentModal from "./request-payment.modal";



function Requests() {
  const [drawer, setDrawer] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [event, setEvent] = useState(null);
  const [eventCompleted, setEventCompleted] = useState(null);
  const [view, setView] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [info, setInfo] = useState(null);
  const [status, setStatus] = useState(null);
  const [detailsModal, setDetailsModal] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const [commentModal, setCommentModal] = useState(null);
  const [paymentModal, setPaymentModal] = useState(null);

  const { authState } = useSelector(
    (state) => ({ authState: state.auth }),
    shallowEqual
  );
  const user = authState?.user;

  const [whereText, setWhereText] = useState(``);

  
  const getPagination = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const where=whereText || `status[]=open&status[]=moved&status[]=completed`;
    const orderBy="order[createdAt]=DESC"
    const userIdquery=`userId=${user.id}`
    const query = createQueryParams(limit, offset, text, relations,where,orderBy,userIdquery);
    return { limit, offset, text, relations, query };
  };

  const getPaginationCountry = () => {
    const limit = pageSize;
    let offset = (pageNumber - 1) * pageSize;
    offset = offset > 1 ? offset : 0;
    const text = searchText.trim() || null;
    const where = "";
    const includeOverview="";
    const onlyActive="onlyActive=false"
    
    const query = createQueryParams(limit, offset, text, countryRelations,where,includeOverview,onlyActive);
    return { limit, offset, text, countryRelations, query };
  };

  const commonProps = {
    view,
    setView,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    searchText,
    setSearchText,
    getPagination,
    getPaginationCountry,
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    editRecord,
    setEditRecord,
    info,
    setInfo,
    status,
    setStatus,
    detailsModal,
    setDetailsModal,
    updateModal,
    setUpdateModal,
    event,
    setEvent,
    eventCompleted,
    setEventCompleted,
    whereText,
    setWhereText,
    commentModal,
    setCommentModal,
    setPaymentModal,
    paymentModal
  };

  return (
    <div>
      <div className="py-4 mt-3 table_main_div d-flex justify-content-between">
        <h1 className="mx-5 mt-2">Request</h1>
      
        <button className="btn mx-5 btn-theme" onClick={() => setDrawer(true)}>
          Add New Request
        </button>
        
      </div>

      <RequestsTable {...commonProps} />
      <RequestsDrawer {...commonProps} />

      {/* Info Modal */}
      <Modal
        width={"80%"}
        open={info}
        onOk={() => setInfo(false)}
        onCancel={() => setInfo(false)}
        footer={""}
      >
        <RequestInfoModal {...commonProps} />
      </Modal>
      {/* Message Modal */}
      <Modal
        width={"60%"}
        open={status}
        onOk={() => setStatus(false)}
        onCancel={() => setStatus(false)}
        footer={""}
      >
        <RequestStatusModal {...commonProps} />
      </Modal>

        {/* Request Detail Modal */}
        <Modal
        width={"60%"}
        open={detailsModal}
        onOk={() => setDetailsModal(false)}
        onCancel={() => setDetailsModal(false)}
        footer={""}
      >
        <RequestDetailModal {...commonProps} />
        
      </Modal>

       {/* Request Update Drawer */}
        <RequestUpdateModal {...commonProps} />
     {/*  */}
      {/* Request Comment Drawer */}
      <RequestCommentModal {...commonProps} />
     {/*  */}
        {/* Request Payment Drawer */}
        <RequestPaymentModal {...commonProps} />
     {/*  */}
      {/* Event Modal */}
      <Modal
        width={"60%"}
        open={event}
        onOk={() => setEvent(false)}
        onCancel={() => setEvent(false)}
        footer={""}
      >
        <EventModal {...commonProps} />
        
      </Modal>
      {/*  */}
      {/* Event CompletedModal */}
      <Modal
        width={"60%"}
        open={eventCompleted}
        onOk={() => setEventCompleted(false)}
        onCancel={() => setEventCompleted(false)}
        footer={""}
      >
        <EventCompletedModal {...commonProps} />
        
      </Modal>
    </div>
  );
}

export default Requests;
