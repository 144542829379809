import { notification } from "antd";
import { CRUD_COMMON_MESSAGE } from "utils/constants/contants";
import * as requestFromServer from "./Crud";
import { requestSlice } from "./Reducer";
const { actions } = requestSlice;

export const loading = (payload) => (dispatch) => {
  dispatch(actions.setLoading(payload));
};

export const getAll = (pagination,refresh) => (dispatch) => {

  refresh && dispatch(actions.setLoading({ getAll: true }));
  return requestFromServer
    .getAll(pagination)
    .then((response) => {
      const { records = [], count = 0 } = response.data;
      dispatch(actions.getAll({ records, count }));
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ getAll: false }));
    });
};

export const create =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .create(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };
  export const createEvent =
  (data, setEvent, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .createEvent(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setEvent(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };

  export const createEventCompleted =
  (id,data, setEventCompleted, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .createEventCompleted(id,data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setEventCompleted(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };


export const remove = (id, setDeleteRecord, pagination) => (dispatch) => {
  dispatch(actions.setLoading({ remove: true }));
  return requestFromServer
    .remove(id)
    .then(({ data }) => {
      if (data) {
        notification.success({ description: CRUD_COMMON_MESSAGE.REMOVE });
        dispatch(getAll(pagination));
        setDeleteRecord(null);
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch(actions.setLoading({ remove: false }));
    });
};

// export const update =
//   (id, data, setDrawer, resetForm, pagination) => (dispatch) => {
//     dispatch(actions.setLoading({ update: true }));
//     return requestFromServer
//       .update(id, data)
//       .then(({ data }) => {
//         if (data) {
//           notification.success({ description: CRUD_COMMON_MESSAGE.UPDATE });
//           dispatch(getAll(pagination));
//           setDrawer(false);
//           resetForm();
//         }
//       })
//       .catch((error) => {})
//       .finally(() => {
//         dispatch(actions.setLoading({ update: false }));
//       });
//   };

  

export const update =
  (id, data,setUpdateModal, pagination) => (dispatch) => {
    return requestFromServer
      .update(id, data)
      .then(({ data }) => {
        if (data) {
          dispatch(getAll(pagination));
          setUpdateModal(false)
        }

      })
      .catch((error) => {})
  };

  

  export const comment =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .comment(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };

  export const payment =
  (data, setDrawer, resetForm, pagination) => (dispatch) => {
    dispatch(actions.setLoading({ create: true }));
    return requestFromServer
      .payment(data)
      .then(({ data }) => {
        if (data) {
          notification.success({ description: CRUD_COMMON_MESSAGE.CREATE });
          dispatch(getAll(pagination));
          setDrawer(false);
          resetForm();
        }
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(actions.setLoading({ create: false }));
      });
  };