import { Pagination, Table  } from "antd";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteConfirmationDialog } from "components/delete-confirmation-dialog/DeleteConfirmationDialog";
import * as actions from "redux/content/Actions";
import * as treatmentActions from "redux/treatment/Actions";
import * as specialitiesAction from "redux/specialities/Actions";
import * as diseaseActions from "redux/diseases/Actions";
import Select from "react-select";
import {
  DeleteButton,
  EditButton} from "components/button/Buttons";

function FilterContentTable(props) {
  const {
    drawer,
    setDrawer,
    deleteRecord,
    setDeleteRecord,
    setEditRecord,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    getPagination,
    whereText,
    setWhereText,
    getAllContent
  } = props;

  const dispatch = useDispatch();
  const pagination = getPagination();
  const paginationContent = getAllContent()
  const {
    user,
    records,
    loading,
    count,
    specialityRecords,
    treatmentRecords,
    diseaseRecords,
  } = useSelector(
    (state) => ({
      user: state.auth.user || null,
      records: state.country?.records || [],
      count: state.content?.count || 0,
      loading: state.content?.loading,
      specialityRecords: state.speciality?.records,
      treatmentRecords: state.treatment?.records || [],
      diseaseRecords: state.disease?.records || [],
      
    }),
    shallowEqual
  );


  const [specility, setSpecility] = useState("");
  const [treatment, setTreatment] = useState("");
  const [disease, setDisease] = useState("");
  const [specilityCondition, setSpecilityCondition] = useState("");
  const [treatmentCondition, setTreatmentCondition] = useState("");
  const [diseaseCondition, setDiseaseCondition] = useState("");

  useEffect(
    () => {
       let specilityQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&${specilityCondition}`;
       let treatmentQuery = `select[name_en]=true&select[id]=true&limit=1000000&offset=0&${treatmentCondition}`;
       let diseaseQuery = `select[name_en]=true&select[id]=true&select[createdAt]=true&limit=1000000&offset=0&${diseaseCondition}`;

      dispatch(specialitiesAction.getAll({ query: specilityQuery }));
      dispatch(diseaseActions.getAll({ query: diseaseQuery }));
      dispatch(treatmentActions.getAll({ query: treatmentQuery }));
    },
    // eslint-disable-next-line
    [specilityCondition,treatmentCondition,diseaseCondition]
  );


  const fetchTabsContent = useCallback(
    () => {
      dispatch(specialitiesAction.getAllRelated(paginationContent));
      dispatch(diseaseActions.getAllRelated(paginationContent));
      dispatch(treatmentActions.getAllRelated(paginationContent));
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      fetchTabsContent();
    },
    // eslint-disable-next-line
    []
  );

  const specialitiesOptions = specialityRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );

  const treatmentOptions = treatmentRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const diseaseOptions = diseaseRecords?.map(
    ({ id: value, name_en }, index) => ({
      value,
      label: name_en,
      index,
    })
  );
  const handleDelete = () => {
    dispatch(actions.remove(deleteRecord?.id, setDeleteRecord, pagination));
  };

  useEffect(() => {
    let queryStringParts = [];
    if (specility) {
      queryStringParts.push(`where[specialityId]=${specility}`);
      setTreatmentCondition(`&where[specialityId]=${specility}`)
    }
    if (treatment) {
      queryStringParts.push(`where[treatmentId]=${treatment}`);
      setDiseaseCondition(`&where[treatmentDisease][treatmentId]=${treatment}`)
    }
    if (disease) {
      queryStringParts.push(`where[diseaseId]=${disease}`);
    }


    // Combine all parts into a single query string
    const newQueryString =
      queryStringParts.length > 0 ? queryStringParts.join("&") : null;
    setWhereText(newQueryString ? newQueryString : null);
  }, [

    specility,
    treatment,
    disease,
    setWhereText,
  ]);

 
  const resetSpecility = () => {
    setSpecility(null);
  };
  const resetTreatment = () => {
    setTreatment(null);
  };
  const resetDisease = () => {
    setDisease(null);
  };


  const columns = [
    {
      title: (
        <>
          Name<span className="form-label-secondary">(English)</span>
        </>
      ),
      dataIndex: "name_en",
      key: "name_en",
      render: (text_en, entity) => {
        return <>{entity?.name_en}</>;
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text_en, entity) => {
        // Construct the URL based on the given conditions
        let url = "";
        if (entity?.content?.countryId) {
          url = `${process.env.REACT_APP_BASE_URL_USER}en/clinics/${entity.name_en?.toLowerCase()?.replace(/ /g, "-")?.replace(/-&-/g, "-")}`;
        }
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "name_de",
      key: "name_de",
      width: "150px",
      render: (name_en, entity) => {
        return (
          <>
            <div className="d-flex mx-auto ">
              
              {(user.role === "admin" ||
                user.role === "author" ||
                user.role === "editor") && (
                <span className="nav-link mx-1">
                  <EditButton
                    onClick={() => {
                      setEditRecord(entity);
                      setDrawer("Country");
                    }}
                  />
                </span>
              )}
              {user.role === "admin" && (
                <span className="nav-link ">
                  <DeleteButton onClick={() => setDeleteRecord(entity)} />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const paginationHandler = (current, pageSize) => {
    setPageSize(pageSize);
    setPageNumber(current);
  };


  return (
    <>
      <div className="pb-2 pt-3 table_main_div " style={{ height: "contain" }}>
       
        <div className="row mx-2 " style={{ paddingRight: "50px" }}>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Specilities</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="Specilities"
                value={
                  specialitiesOptions?.find(
                    (option) => option?.value === specility
                  ) || null
                }
                options={specialitiesOptions}
                onChange={(selectedOption) => {
                  setSpecility(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetSpecility}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
               <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Treatments</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="tratments"
                value={
                  treatmentOptions?.find(
                    (option) => option?.value === treatment
                  ) || null
                }
                options={treatmentOptions}
                onChange={(selectedOption) => {
                  setTreatment(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetTreatment}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
               <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <span className="drawer_span">Disease</span>
            <div className="custom-select-container-content" style={{ display: "flex", alignItems: "center" }}>
              <Select
                isMulti={false}
                name="diseases"
                value={
                  diseaseOptions?.find((option) => option?.value === disease) ||
                  null
                }
                options={diseaseOptions}
                onChange={(selectedOption) => {
                  setDisease(selectedOption ? selectedOption.value : null);
                }}
                className="drawer_input form-control w-100 basic-multi-select multiSelectBox"
                classNamePrefix="select"
              />
              <b
                onClick={resetDisease}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              >
                <i class="bi bi-x-circle-fill"></i>
              </b>
            </div>
          </div>
        </div>
        
        <div className="domain_history table_main py-3 px-2">
          <Table
            className="table_tag"
            size="small"
            loading={loading?.getAll || false}
            // scroll={{ y: "calc(100vh - 420px)" }}
            scroll={{ x: "calc(100vh - 420px)" }}
            pagination={false}
            dataSource={records}
            columns={columns}
          />
          <div
            className="d-flex mt-3 mr-5 justify-content-end"
            style={{ marginRight: "20px" }}
          >
            <Pagination
              showSizeChanger
              onChange={paginationHandler}
              onShowSizeChange={paginationHandler}
              pageSize={pageSize}
              defaultCurrent={1}
              total={count}
            />
          </div>
        </div>
      </div>

      <DeleteConfirmationDialog
        loading={loading?.remove}
        open={deleteRecord}
        onClose={setDeleteRecord}
        onDelete={handleDelete}
        title="Delete"
        content="Are you sure you want to delete it?"
        onCancelLabel="Cancel"
        onConfirmLabel="Delete"
      />
    </>
  );
}

export default FilterContentTable;
